<template>
  <div class="body1" v-if="!initLoading">
    <a-row style="padding: 30px 200px;">
      <a-steps :current="stepIndex" labelPlacement="vertical" size="small">
        <a-step :title="$t('booking_fill')"/>
        <a-step :title="$t('booking_confirm')"/>
        <a-step :title="$t('booking_pay')"/>
      </a-steps>
    </a-row>
    <a-row :gutter="[20,0]" style="margin-bottom:30px;">
      <a-col :span="17" v-if="stepIndex === 0">
        <a-form :colon="false" :hideRequiredMark="true" :model="formState" autocomplete="off" layout="inline" @finish="finish1" :scrollToFirstError="true">
          <div class="border order_fill">
            <a-alert v-if="!isEmpty(warningText)" :type="warningType" closable show-icon style="margin-bottom: 10px">
              <template #message>{{ warningText }}</template>
            </a-alert>
            <div class="hotel-name">
              <strong>
                <template v-if="isZhCn">
                  {{
                    isEmpty(hotelInfo.hotel_name_cn) ? hotelInfo.hotel_name_en : (hotelInfo.hotel_name_cn + ' (' + hotelInfo.hotel_name_en + ')')
                  }}
                </template>
                <template v-else>
                  {{ hotelInfo.hotel_name_en }}
                </template>
              </strong>
              <span>
                <StarFilled class="star-color1" v-for="i in parseInt(hotelInfo.hotel_star)" v-bind:key="i"/>
              </span>
            </div>
            <div class="hotel-address">
              <template v-if="isZhCn">
                {{ isEmpty(hotelInfo.hotel_address_cn) ? hotelInfo.hotel_address_en : hotelInfo.hotel_address_cn }}
              </template>
              <template v-else>
                {{ hotelInfo.hotel_address_en }}
              </template>
            </div>
            <div class="hotel-room">
              <strong>
                <template v-if="roomRate.room_id > 0">
                  {{
                    isZhCn ? getNameCnEn(roomRate.room_name_cn, roomRate.room_name_en) : roomRate.room_name_en
                  }}{{ preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : '' }}
                </template>
                <template v-else>
                  {{ roomRate.RatePlans[0].RatePlanName }}{{
                    preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : ''
                  }}
                </template>
              </strong>
              <strong>{{ dayjs(preOrder.SearchData.CheckIn).format(checkInOutFormat) }} ~
                {{ dayjs(preOrder.SearchData.CheckOut).format(checkInOutFormat) }}</strong>
            </div>
            <a-divider/>
            <div class="occ-info">
              <p><span class="order-fill-title">{{ $t('occupancy_info') }}</span></p>
              <p style="display: flex;align-items: center;padding-left: 10px">
                <span style="margin-right: 10px;"><template v-if="preOrder.Rate.EpsRateInfo">{{ $t('residency') }}</template><template v-else>{{ $t('nationality') }}</template></span>
                <a-select show-search :dropdownMatchSelectWidth="false" v-model:value="formState.nationality" :options="natiOptions" :filter-option="filterOption" style="width: 150px"></a-select>
                <span class="remark"><template v-if="preOrder.Rate.EpsRateInfo">{{ $t('nationality_remark_eps') }}</template><template v-else>{{ $t('nationality_remark') }}</template></span>
              </p>
              <a-divider dashed style="margin: 5px 0px"/>
              <div style="display: flex;align-items: center;padding-left: 10px;margin-bottom: 10px">
                <table>
                  <tbody>
                    <template v-for="(roomGuest,roomIndex) in formState.guestNames" v-bind:key="roomIndex">
                      <template v-for="(name,nameIndex) in roomGuest" v-bind:key="nameIndex">
                        <tr>
                          <td style="width:80px;vertical-align: top;"><template v-if="nameIndex === 0">{{ $t('room') }}{{ roomIndex + 1 }}</template></td>
                          <td style="min-width:120px;text-align: right;vertical-align: top;">
                            <span v-if="nameIndex === 0" class="error-color">*</span>
                            <template v-if="roomIndex === 0">
                              <a-popover v-if="nameIndex === 0" placement="topLeft">
                                <template #title v-if="isZhCn">
                                  请按照入住时所持证件的姓名填写，预订港澳台及国际酒店，以英文字母填写。
                                </template>
                                <template #title v-else>
                                  Please enter your ID name for check-in. For Hong Kong, Macao, Taiwan and other international hotels, please enter pinyin.
                                </template>
                                <template #content v-if="isZhCn">
                                  <div>
                                    <p>中文姓名填写示例：<br />
                                      例如：“张三”，姓氏拼音填“Zhang”，名字填“San”。</p>
                                    英文姓名填写示例：<br />
                                    例如： “Matthew Robert Smith”，姓填 “Smith”，名填”MatthewRobert”。
                                  </div>
                                </template>
                                <template #content v-else>
                                  <div>
                                    <p>Chinese name example: <br />
                                      “张三”: the first name is “San”, the last name is “Zhang”.</p>
                                    English name example: <br />
                                    “Matthew Robert Smith“, last name is “Smith“, first name is “MatthewRobert“.
                                  </div>
                                </template>
                                {{ $t('guest_name_main') }}
                                <QuestionCircleFilled class="warning-color" />
                              </a-popover>
                              <a-tooltip v-else placement="topLeft" :color="'#ffffff'">
                                <template #title v-if="isZhCn">
                                  <div style="color: #000000;">入住时，必须由“主入住人”办理手续，其他入住人无法单独办理手续。</div>
                                </template>
                                <template #title v-else>
                                  <span style="color: #000000">When you check in, you must be checked in by the "Primary occupant". No other occupant can check in alone.</span>
                                </template>
                                {{ $t('guest_name_other') }}
                                <QuestionCircleFilled class="warning-color" />
                              </a-tooltip>
                            </template>
                            <template v-else>
                              <span v-if="nameIndex === 0">{{ $t('guest_name_main') }}</span>
                              <span v-else>{{ $t('guest_name_other') }}</span>
                            </template>
                          </td>
                          <td style="width: 600px;vertical-align: top;">
                            <a-space style="width: 100%" align="start">
                              <a-form-item :name="['guestNames', roomIndex, nameIndex, 'LastName']" :rules="[{ required: nameIndex === 0, asyncValidator: validateLastName }]" style="width: 210px">
                                <a-input v-model:value="name.LastName" :placeholder="$t('last_name')" />
                              </a-form-item>
                              <a-form-item :name="['guestNames', roomIndex, nameIndex, 'FirstName']" :rules="[{ required: nameIndex === 0, asyncValidator: validateFirstName }]" style="width: 210px">
                                <a-input v-model:value="name.FirstName" :placeholder="$t('first_name')" />
                              </a-form-item>
                              <a-button v-if="preOrder.SearchData.PaxRoom.Adults > roomGuest.length" type="link" size="small" @click="addGuestName(roomIndex, nameIndex, true)">
                                <template #icon><PlusCircleFilled /></template>
                              </a-button>
                              <a-button v-if="nameIndex > 0" type="link" size="small" @click="addGuestName(roomIndex, nameIndex, false)">
                                <template #icon><MinusCircleFilled /></template>
                              </a-button>
                            </a-space>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <a-divider />
            <div>
              <p><span class="order-fill-title">{{ $t('contact_info') }}</span></p>
              <a-row class='p21' :gutter="[20,0]">
                <a-col :span="8">
                  <a-form-item :name="['contactInfo', 'name']" :rules="[{ required: true, message: '' }]" :label="$t('full_name')"><a-input v-model:value="formState.contactInfo.name" :placeholder="$t('full_name')" /></a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item :name="['contactInfo', 'email']" :rules="[{ required: true, type: 'email', message: '' }]" label="Email"><a-input v-model:value="formState.contactInfo.email" placeholder="Email" /></a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item :name="['contactInfo', 'tel']" :rules="[{ required: true, pattern: /^(1[3456789]\d{9}|0\d{2,3}-\d{7,8})$/, message: '' }]" :label="$t('contact_tel')"><a-input v-model:value="formState.contactInfo.tel" :placeholder="$t('contact_tel')" /></a-form-item>
                </a-col>
              </a-row>
            </div>
            <template v-if="isERoom(preOrder.Rate.RatePlanId) && preOrder.Rate?.EpsRateInfo?.sale_scenario?.package">
              <a-divider />
              <p><span class="order-fill-title">{{ $t('air_ticket_info') }}</span></p>
              <a-row style="padding:0 20px 10px 10px">
                <a-col>
                  <a-checkbox :checked="!isEmpty(formState.ticketInfo.flightNo)">
                    <span class="remark1">{{ $t('air_ticket_info_remark') }}</span></a-checkbox>
                </a-col>
              </a-row>
              <a-row class='p21' :gutter="[20,0]">
                <a-col :span="12">
                  <a-form-item :name="['ticketInfo', 'flightNo']" :rules="[{ required: true, message: '' }]" :label="$t('flight_no')"><a-input v-model:value="formState.ticketInfo.flightNo" /></a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :name="['ticketInfo', 'ticketNo']" :rules="[{ required: true, message: '' }]" :label="$t('air_ticket_no')"><a-input v-model:value="formState.ticketInfo.ticketNo" /></a-form-item>
                </a-col>
              </a-row>
              <a-row style="padding:20px 20px 0 10px" :gutter="[20,0]">
                <a-col :span="8">
                  <a-form-item :name="['ticketInfo', 'date']" :rules="[{ required: true, message: '' }]" :label="$t('date')"><a-input v-model:value="formState.ticketInfo.date" /></a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item :name="['ticketInfo', 'departure']" :rules="[{ required: true, message: '' }]" :label="$t('air_departure')"><a-input v-model:value="formState.ticketInfo.departure" /></a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item :name="['ticketInfo', 'landing']" :rules="[{ required: true, message: '' }]" :label="$t('air_landing')"><a-input v-model:value="formState.ticketInfo.landing" /></a-form-item>
                </a-col>
              </a-row>
            </template>
            <a-divider />
            <div>
              <p><span class="order-fill-title">{{ $t('group_booking_id') }}</span></p>
              <div class='p21'>
                <a-form-item name="groupBookingId"><a-input v-model:value="formState.groupBookingId" :placeholder="$t('group_booking_id_remark')" /></a-form-item>
              </div>
            </div>
            <a-divider />
            <div>
              <p><span class="order-fill-title">{{ $t('special_request') }}</span><span class="remark">{{ $t('special_request_remark')}}</span></p>
              <div style="padding-left: 10px">
                <a-form-item name="specialRequests">
                  <a-checkbox-group v-model:value="formState.specialRequests" style="width: 100%">
                    <a-row>
                      <template v-for="(item, index) in SpecialRequestItems" v-bind:key="index">
                        <a-col :span="6" v-if="index < 4 || showSR">
                          <a-checkbox :value="item.value"><template v-if="isZhCn">{{ item.label }}<br/>{{ item.value }}</template><template v-else>{{ item.value }}</template></a-checkbox>
                        </a-col>
                      </template>
                    </a-row>
                  </a-checkbox-group>
                </a-form-item>
              </div>
              <div style="display: flex;justify-content: center;padding-top: 10px">
                <a @click="showSR = !showSR">
                  {{ showSR ? $t('hide') : $t('more') }}
                  <template v-if="showSR">
                    <UpOutlined/>
                  </template>
                  <template v-else>
                    <DownOutlined/>
                  </template>
                </a>
              </div>
            </div>
            <a-divider />
            <div>
              <p><span class="order-fill-title">{{ $t('booking_reminder') }}</span></p>
              <div v-if="this.usefulInfoList" class='p21'>
                <div v-for="item in this.usefulInfoList" v-bind:key="item.id">
                  <useful-info-item :useful-info-item="item" :is-zh-cn="this.isZhCn"></useful-info-item>
                </div>
                <div class="reminder-content">
                  <template v-if="isZhCn">
                    <div>
                      入住国内酒店时请使用名字拼音查询（名+姓）预订。<br />
                      请于页面展示酒店最晚入住时间前办理入住，无展示规定最晚入住时间时，请于首晚22点前办理入住。
                    </div>
                    <div>
                      <div class="reminder-title">出行提示</div>
                      订单价格已含房费及一般税费，不包含城市税（欧洲）、度假村费、设施费（北美）、市政费（迪拜）、销售与服务税（SST,马来西亚）等酒店向客人另行征收的费用；客人在酒店产生的额外消费、增项服务费、违规罚款等一切费用，需客人跟酒店另行结算。
                    </div>
                    <div>
                      <div class="reminder-title">未到店或晚至</div>
                      如订单首晚未到店(No Show)或延迟入住，请提前联系客服，否则酒店可能不会以任何方式通知客人，并直接取消整单不退费。
                    </div>
                    <div>
                      <div class="reminder-title">其他</div>
                      少数酒店不允许儿童/未成年人入住，或需加收费用。如您有未成年人同行，请付款前与我们确认。<br />
                      您的特殊需求，酒店视当天入住情况尽量安排，不做任何保证。<br />
                      所有订单超过最晚免费取消日均视为不可修改，如您有特殊情况，可联系客服协助处理。<br />
                      请在订单的离店后的90天内申请发票，逾期将无法开具发票，谢谢您的理解。
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      If this is a Chinese hotel, please use the pinyin of the name(last name+first name) upon check-in.<br />
                      Please arrive before the latest check-in time. If no latest check-out time is specified, please arrive before 10 pm.
                    </div>
                    <div>
                      <div class="reminder-title">Know before you go</div>
                      Service fees and tax are included in the total amount. However please note that fees like city tax (Europe), resort fee, facility fee (north America), municipal fees (Dubai), Sales & Services Tax (SST, Malaysia) etc, are charged at the hotel. The extra consumption, service fee, penalty etc, should be paid to the hotel separately.
                    </div>
                    <div>
                      <div class="reminder-title">No show or late show</div>
                      If no show or late show, please contact our customer service in advance. Otherwise, the hotel might cancel the booking without any notice with full charge.
                    </div>
                    <div>
                      <div class="reminder-title">Other</div>
                      Some hotels may not allow children to stay in, or extra charge might be applied. Please confirm with us before making the payment.<br />
                      Your special requests are subject to availability on arrival, and it is not guaranteed by Jidu.<br />
                      No amendments can be made after latest cancellation date. If you have special request, please contact our customer service.<br />
                      Please apply your invoice 90 days after check-out. Invoice cannot be applied if overdue. Thanks you for your understanding.
                    </div>
                  </template>
                </div>
              </div>
              <div v-else class='p21'>
                <div class="hotel-policy-content">
                  <div><strong>{{ $ta('checkin|and|checkout') }}</strong></div>
                  <span class="hotel-policy-item"><CheckOutlined /> {{ $t('hanlde_checkin_time') }}: <span class="hotel-policy-item-value">{{ this.Policy.begin_time }} - {{ this.Policy.end_time }}</span></span>
                  <span class="hotel-policy-item"><CheckOutlined /> {{ $t('checkout_time') }}: <span class="hotel-policy-item-value">{{ this.Policy.checkout_time }}</span></span>
                  <span class="hotel-policy-item" v-if="this.Policy.min_age" ><CheckOutlined /> {{ $t('checkin_min_age', {n: this.Policy.min_age}) }} </span>
                </div>
                <div class="hotel-policy-content1" v-if="this.Policy.special_instructions">
                  <div><strong>{{ $t('special_check_in_instructions') }}</strong></div>
                  <div class="ti28" v-html="this.Policy.special_instructions"></div>
                </div>
                <div class="hotel-policy-content1" v-if="this.Policy.instructions">
                  <div><strong>{{ $t('check_in_instructions') }}</strong></div>
                  <div class="ex-content" v-html="this.Policy.instructions">
                  </div>
                </div>
                <div class="hotel-policy-content1" v-if="this.Policy.fee_mandatory">
                  <div><strong>{{ $t('mandatory_fees') }}</strong></div>
                  <div class="ex-content" v-html="this.Policy.fee_mandatory">
                  </div>
                </div>
                <div class="hotel-policy-content1" v-if="this.Policy.fee_optional">
                  <div><strong>{{ $t('optional_extras') }}</strong></div>
                  <div class="ex-content" v-html="this.Policy.fee_optional">
                  </div>
                </div>
                <div class="hotel-policy-content1">
                  <div><strong>{{ $t('know_before_you_go') }}</strong></div>
                  <div class="ex-content" v-html="this.Policy.policie_know_before_you_go">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width:100%;margin: 15px 0;display: flex;justify-content: right">
            <a-button type="link" @click="backHotel">{{ $ta('back|hotel_detail') }}</a-button>
            <a-button type="primary" html-type="submit">{{ $t('next') }}, {{ $t('booking_confirm') }}</a-button>
          </div>
        </a-form>
      </a-col>
      <a-col :span="17" v-if="stepIndex === 1">
        <div class="border order_fill">
          <div class="hotel-name">
            <strong>
              <template v-if="isZhCn">
                {{
                  isEmpty(hotelInfo.hotel_name_cn) ? hotelInfo.hotel_name_en : (hotelInfo.hotel_name_cn + ' (' + hotelInfo.hotel_name_en + ')')
                }}
              </template>
              <template v-else>
                {{ hotelInfo.hotel_name_en }}
              </template>
            </strong>
            <span>
                <StarFilled class="star-color1" v-for="i in parseInt(hotelInfo.hotel_star)" v-bind:key="i"/>
              </span>
          </div>
          <div class="hotel-address">
            <template v-if="isZhCn">
              {{ isEmpty(hotelInfo.hotel_address_cn) ? hotelInfo.hotel_address_en : hotelInfo.hotel_address_cn }}
            </template>
            <template v-else>
              {{ hotelInfo.hotel_address_en }}
            </template>
          </div>
          <div class="hotel-room">
            <strong>
              <template v-if="roomRate.room_id > 0">
                {{
                  isZhCn ? getNameCnEn(roomRate.room_name_cn, roomRate.room_name_en) : roomRate.room_name_en
                }}{{ preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : '' }}
              </template>
              <template v-else>
                {{ roomRate.RatePlans[0].RatePlanName }}{{
                  preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : ''
                }}
              </template>
            </strong>
            <strong>{{ dayjs(preOrder.SearchData.CheckIn).format(checkInOutFormat) }} ~
              {{ dayjs(preOrder.SearchData.CheckOut).format(checkInOutFormat) }}</strong>
          </div>
          <a-divider/>
          <div class="occ-info">
            <p><span class="order-fill-title">{{ $t('occupancy_info') }}</span></p>
            <table class="border order_fill_table">
              <thead>
                <tr>
                  <th>{{ $t('nationality') }}</th>
                  <th>{{ $t('per_room') }}</th>
                  <th>{{ $t('guest_name') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ getCountryName(formState.nationality) }}
                  </td>
                  <td>
                    {{ $t('adult_unit', {'n': preOrder.SearchData.PaxRoom.Adults}) }},
                    {{ $t('child_unit', {'n': preOrder.SearchData.PaxRoom.Children.length}) }}
                    <template v-if="preOrder.SearchData.PaxRoom.Children.length > 0">
                      (<template v-for="(age,index) in preOrder.SearchData.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
                    </template>
                  </td>
                  <td>
                    <template v-for="(roomGuest,roomIndex) in formState.guestNames" v-bind:key="roomIndex">
                      <template v-for="(name,nameIndex) in roomGuest" v-bind:key="nameIndex">
                        {{name.LastName}} {{name.FirstName}};
                      </template>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <a-divider />
          <div>
            <p><span class="order-fill-title">{{ $t('cancel_policy') }}</span></p>
            <template v-if="!preOrder.Rate.EpsRateInfo">
              <template v-if="!isRefundable(preOrder.Rate.CancelAdvanceHour, dayjs(preOrder.SearchData.CheckIn), dayjs())">
                <div class="error-color">{{ $t('non_refundable_remark') }}</div>
              </template>
              <template v-else>
                <table class="border order_fill_table">
                  <thead>
                  <tr>
                    <th>{{ $t('date') }}</th>
                    <th>{{ $t('cancellation_fee') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ $t('from', { 'str': getCancelDatetime(dayjs(preOrder.SearchData.CheckIn), preOrder.Rate.CancelAdvanceHour) }) }}
                    </td>
                    <td>
                      {{ preOrder.Rate.Currency }} {{ $filters.formatAmount(preOrder.Rate.TotalPrice, 0) }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </template>
            <template v-else>
              <template v-if="!preOrder.Rate.EpsRateInfo?.refundable">
                <div class="error-color" style="padding-left: 10px">{{ $t('non_refundable_remark') }}</div>
              </template>
              <template v-else-if="isEpsPartiallyRefundable(dayjs, preOrder.Rate.EpsRateInfo)">
                <table class="border order_fill_table">
                  <tbody>
                  <tr>
                    <td>
                      <div>{{ $t('cancel_policy_info_3', { f: getECancelText(preOrder.Rate.EpsRateInfo.cancel_penalties[0], $t, preOrder.SearchData.PaxRoom.Count) }) }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <table class="border order_fill_table">
                  <tbody>
                  <tr>
                    <td>
                      <div>{{ $t('free_cancel_before', { str: dayjs(preOrder.Rate.EpsRateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }};</div>
                      <div v-for="(item,index) in preOrder.Rate.EpsRateInfo.cancel_penalties" v-bind:key="index">
                        {{ $t('cancel_policy_info_1', { s: dayjs(item.start).format('YYYY-MM-DD HH:mm'), e: dayjs(item.end).format('YYYY-MM-DD HH:mm'), f: getECancelText(item, $t, preOrder.SearchData.PaxRoom.Count) }) }}
                      </div>
                      <div>{{ $t('cancel_policy_info_2', { e: dayjs(preOrder.Rate.EpsRateInfo.cancel_penalties[preOrder.Rate.EpsRateInfo.cancel_penalties.length - 1].end).format('YYYY-MM-DD HH:mm') }) }} ({{ $t('beijing_time_remark') }})</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </div>
          <a-divider />
          <div>
            <p><span class="order-fill-title">{{ $t('contact_info') }}</span></p>
            <table class="border order_fill_table">
              <thead>
              <tr>
                <th>{{ $t('full_name') }}</th>
                <th>Email</th>
                <th>{{ $t('contact_tel') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{formState.contactInfo.name}}
                </td>
                <td>
                  {{formState.contactInfo.email}}
                </td>
                <td>
                  {{formState.contactInfo.tel}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <template v-if="isERoom(preOrder.Rate.RatePlanId) && preOrder.Rate?.EpsRateInfo?.sale_scenario?.package">
            <a-divider dashed/>
            <div>
              <p><span class="order-fill-title">{{ $t('air_ticket_info') }}</span></p>
              <table class="border order_fill_table">
                <thead>
                <tr>
                  <th>{{ $t('flight_no') }}</th>
                  <th>{{ $t('air_ticket_no') }}</th>
                  <th>{{ $t('date') }}</th>
                  <th>{{ $t('air_departure') }}</th>
                  <th>{{ $t('air_landing') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    {{formState.ticketInfo.flightNo}}
                  </td>
                  <td>
                    {{formState.ticketInfo.ticketNo}}
                  </td>
                  <td>
                    {{formState.ticketInfo.date}}
                  </td>
                  <td>
                    {{formState.ticketInfo.departure}}
                  </td>
                  <td>
                    {{formState.ticketInfo.landing}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-if="formState.groupBookingId.length > 0">
            <a-divider dashed/>
            <div>
              <p><span class="order-fill-title">{{ $t('group_booking_id') }}</span></p>
              <div class='p21'>
                {{ formState.groupBookingId }}
              </div>
            </div>
          </template>
          <template v-if="formState.specialRequests.length > 0">
            <a-divider dashed/>
            <div>
              <p><span class="order-fill-title">{{ $t('special_request') }}</span><span class="remark">{{ $t('special_request_remark')}}</span></p>
              <div class='p21'>
                <template v-for="(item, index) in formState.specialRequests" v-bind:key="index">
                {{ item }};
                </template>
              </div>
            </div>
          </template>
        </div>
        <div style="width:100%;margin: 15px 0;display: flex;justify-content: right">
          <a-button type="link" @click="stepIndex=0">{{ $ta('back|update') }}</a-button>
          <a-button type="primary" @click="finish2">{{ $t('next') }}, {{ $t('booking_pay') }}</a-button>
        </div>
      </a-col>
      <a-col :span="7">
        <div class="order_price" v-if="!preOrder.Rate?.EpsRateInfo">
          <span>{{ $t('total_price') }}</span>
          <span>{{ preOrder.Rate.Currency }} {{ $filters.formatAmount(preOrder.Rate.TotalPrice, 0) }}</span>
        </div>
        <div class="order_price" v-else>
          <a-popover placement="bottomLeft" trigger="click">
            <template #content>
              <e-rate-detail :check-in="preOrder.SearchData.CheckIn" :room-count="preOrder.SearchData.PaxRoom.Count" :e-occ-pricing="EOccPricing" :currency="preOrder.Rate.Currency" :total-price="preOrder.Rate.TotalPrice" :is-order-detail="false"></e-rate-detail>
            </template>
            <template #title>
              <span>{{ $ta('price|detail') }}</span>
            </template>
            <div style="cursor: pointer;">
              <QuestionCircleOutlined /> <span style="text-decoration-line: underline;">{{ $t('total_price') }}</span>
            </div>
          </a-popover>
          <span>{{ preOrder.Rate.Currency }} {{ $filters.formatAmount(preOrder.Rate.TotalPrice, 0) }}</span>
        </div>
        <div class="border order_info">
          <div><strong>{{ $t('booking_info') }}</strong></div>
          <a-divider/>
          <table>
            <tbody>
            <tr>
              <td style="width:80px;text-align: right">{{ $t('checkin_date') }}</td>
              <td>{{ dayjs(preOrder.SearchData.CheckIn).format(checkInOutFormat) }}</td>
            </tr>
            <tr>
              <td style="text-align: right">{{ $t('checkout_date') }}</td>
              <td>{{ dayjs(preOrder.SearchData.CheckOut).format(checkInOutFormat) }}</td>
            </tr>
            <tr>
              <td style="text-align: right">{{ $t('nights') }}</td>
              <td>
                {{ $t('night_unit', {n: getNights(dayjs(preOrder.SearchData.CheckIn), dayjs(preOrder.SearchData.CheckOut))}) }}
              </td>
            </tr>
            <tr>
              <td style="text-align: right;vertical-align: top">{{ $t('room_type') }}</td>
              <td v-if="preOrder.room_id > 0">
                {{
                  isZhCn ? getNameCnEn(preOrder.room_name_cn, preOrder.room_name_en) : preOrder.room_name_en
                }}{{ preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : '' }}
              </td>
              <td v-else>
                {{ preOrder.Rate.RatePlanName }}{{
                  preOrder.SearchData.PaxRoom.Count > 1 ? ' x ' + preOrder.SearchData.PaxRoom.Count : ''
                }}
              </td>
            </tr>
            <tr v-if="preOrder.SearchData.BedGroupId">
              <td style="text-align: right;vertical-align: top">{{ $t('select_bedtype') }}</td>
              <td>
                {{ preOrder.Rate.EpsRateInfo?.bed_groups[preOrder.SearchData.BedGroupId].description }}
                <div class="error-color f13">
                  {{ $t('select_bedtype_warning') }}
                </div>
              </td>
            </tr>
            <tr>
              <td style="text-align: right;vertical-align: top">{{ $t('per_room') }}</td>
              <td>
                {{ $t('adult_unit', {'n': preOrder.SearchData.PaxRoom.Adults}) }},
                {{ $t('child_unit', {'n': preOrder.SearchData.PaxRoom.Children.length}) }}
                <template v-if="preOrder.SearchData.PaxRoom.Children.length > 0">
                  (<template v-for="(age,index) in preOrder.SearchData.PaxRoom.Children" v-bind:key="index"><template v-if="index > 0">, </template>{{ $t('year_old', {n: age}) }}</template>)
                </template>
              </td>
            </tr>
            <tr>
              <td style="text-align: right;">{{ $t('meal_type') }}</td>
              <td>
                {{ $t('mealtype_unit.' + preOrder.Rate.MealType, {n: preOrder.SearchData.PaxRoom.Count * preOrder.Rate.Occupancy}) }}
              </td>
            </tr>
            <tr>
              <td style="text-align: right;vertical-align: top">{{ $t('cancel_policy') }}</td>
              <td>
                <template v-if="!preOrder.Rate?.EpsRateInfo">
                  <template v-if="!isRefundable(preOrder.Rate.CancelAdvanceHour, dayjs(preOrder.SearchData.CheckIn), dayjs())">
                    <div class="error-color">{{ $t('non_refundable_remark') }}</div>
                  </template>
                  <template v-else>
                    {{
                      $t('cancel_policy_info', {
                        str: getCancelDatetime(dayjs(preOrder.SearchData.CheckIn), preOrder.Rate.CancelAdvanceHour),
                        amount: $filters.formatAmount(preOrder.Rate.TotalPrice, 0)
                      })
                    }}
                  </template>
                </template>
                <template v-else>
                  <template v-if="!preOrder.Rate?.EpsRateInfo?.refundable">
                    <div class="error-color">{{ $t('non_refundable_remark') }}</div>
                  </template>
                  <template v-else-if="isEpsPartiallyRefundable(dayjs, preOrder.Rate.EpsRateInfo)">
                    <div>{{ $t('cancel_policy_info_3', { f: getECancelText(preOrder.Rate.EpsRateInfo.cancel_penalties[0], $t, preOrder.SearchData.PaxRoom.Count) }) }}</div>
                  </template>
                  <template v-else>
                    <div>
                      {{ $t('free_cancel_before', { str: dayjs(preOrder.Rate.EpsRateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }};
                    </div>
                    <div v-for="(item,index) in preOrder.Rate.EpsRateInfo.cancel_penalties" v-bind:key="index">
                      {{ $t('cancel_policy_info_1', { s: dayjs(item.start).format('YYYY-MM-DD HH:mm'), e: dayjs(item.end).format('YYYY-MM-DD HH:mm'), f: getECancelText(item, $t, preOrder.SearchData.PaxRoom.Count) }) }}
                    </div>
                    <div>{{ $t('cancel_policy_info_2', { e: dayjs(preOrder.Rate.EpsRateInfo.cancel_penalties[preOrder.Rate.EpsRateInfo.cancel_penalties.length - 1].end).format('YYYY-MM-DD HH:mm') }) }}</div>
                    <div>({{ $t('beijing_time_remark') }})</div>
                  </template>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
          <a-divider>
            <svg-flash-deal-solid/>
            <span>&nbsp;&nbsp;{{ $t('immediately_confirm') }}</span></a-divider>
          <div style="font-size: 13px;">{{ $t('immediately_confirm_remark') }}</div>
        </div>
        <div class="border order_info" v-if="!isEmpty(roomRate.RatePlans[0].Remark)">
          <div><strong class="warning-color">{{ $t('rate_remark') }}</strong></div>
          <a-divider/>
          <div>{{ roomRate.RatePlans[0].Remark }}</div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="body1" v-else>
    <a-spin :tip="$t('confirm_price_loading')" :spinning="initLoading" size="large">
      <div style="width: 100%;height: 500px"></div>
    </a-spin>
  </div>

  <a-modal v-model:visible="createVisible" :closable="false" :maskClosable="false" :title="null" :footer="null">
    <a-spin :spinning="createVisible" size="large" :style="{width: '100%',margin:'30px auto'}">
      <template #tip>
        {{$t('create_booking_loading')}}
      </template>
    </a-spin>
  </a-modal>
</template>

<script>
import {inject} from "vue";
import { Modal } from 'ant-design-vue';
import dayjs from 'dayjs';
import Decimal from "decimal.js";
import {isEmpty, getNameCnEn, isRefundable, getCancelDatetime, getNights, isERoom, getECancelText, isEpsPartiallyRefundable} from "@/utils/util";
import {
  StarFilled,
  PlusCircleFilled,
  MinusCircleFilled,
  UpOutlined,
  DownOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  CheckOutlined
} from '@ant-design/icons-vue';
import SvgFlashDealSolid from '@/assets/icon/flash-deal-solid.svg';
import {confirmRate} from "@/services/hotel";
import {AllCountries, CommonNationalityCodes} from "@/data/country";
import {SpecialRequestItems} from "@/data/special-request-item";
import UsefulInfoItem from "@/views/hotel/UsefulInfoItem";
import '@/assets/css/fill.less'
import {create} from "@/services/booking";
import ERateDetail from "@/views/booking/ERateDetail";

export default {
  name: "BookingFill",
  components: {
    SvgFlashDealSolid,
    StarFilled,
    PlusCircleFilled,
    MinusCircleFilled,
    UpOutlined,
    DownOutlined,
    UsefulInfoItem,
    QuestionCircleFilled,
    QuestionCircleOutlined,
    CheckOutlined,
    ERateDetail
  },
  data() {
    let preOrderKey = this.$route.params.PreOrderKey
    let preOrder = JSON.parse(sessionStorage.getItem(process.env.VUE_APP_PRE_ORDER_KEY_PREFIX + preOrderKey))
    if(preOrder === null) {
      window.location.href = '/home'
    }

    let EOccPricing = null
    if(isERoom(preOrder.Rate.RatePlanId) && preOrder.Rate.EpsRateInfo) {
      EOccPricing = Object.values(preOrder.Rate.EpsRateInfo.occupancy_pricing)[0]
    }

    this.confirmRate(preOrder)

    let currentUser = this.$store.getters["account/getUser"]

    let guestNames = []
    for(let i = 0;i < preOrder.SearchData.PaxRoom.Count;i ++) {
      if(currentUser.is_test || currentUser.is_eps_test)
        guestNames.push([{ FirstName: 'San', LastName: 'Zhang' }])
      else
        guestNames.push([{ FirstName: '', LastName: '' }])
    }
    const formState = {}
    formState.nationality = preOrder.SearchData.PaxRoom.Nationality
    formState.guestNames = guestNames
    formState.contactInfo = {
      name: currentUser.user_name,
      tel: currentUser.user_tel,
      email: currentUser.user_email
    }
    formState.ticketInfo = {
      flightNo: '',
      date: '',
      departure: '',
      landing: '',
      ticketNo: ''
    }
    if((currentUser.is_test || currentUser.is_eps_test) && isERoom(preOrder.Rate.RatePlanId) && preOrder.Rate?.EpsRateInfo?.sale_scenario?.package)   {
      formState.ticketInfo.flightNo = 'CZ3505'
      formState.ticketInfo.date = preOrder.SearchData.CheckIn
      formState.ticketInfo.departure = 'PVG 浦东国际机场'
      formState.ticketInfo.landing = 'NRT 成田国际机场'
      formState.ticketInfo.ticketNo = 'XXXXXXXXXXXXX'
    }

    formState.groupBookingId = ''
    formState.specialRequests = []

    let validateLastName = async (_rule, value) => {
      if(_rule.required && (isEmpty(value) || value.length === 1)){
        return Promise.reject(this.$t('last_name_error'));
      } else if (!/^[A-Za-z]{2,}$/.test(value)) {
        return Promise.reject(this.$t('last_name_error'));
      } else {
        return Promise.resolve();
      }
    }
    let validateFirstName = async (_rule, value) => {
      if(_rule.required && (isEmpty(value) || value.length === 1)){
        return Promise.reject(this.$t('first_name_error'));
      } else if (!/^[A-Za-z]+\s?[A-Za-z]+$/.test(value)) {
        return Promise.reject(this.$t('first_name_error'));
      } else {
        return Promise.resolve();
      }
    }

    return {
      dayjs,
      Decimal,
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      selectedMenuKeys: inject('selectedMenuKeys'),
      stepIndex: 0,
      initLoading: true,
      preOrder,
      roomRate: undefined,
      hotelInfo: undefined,
      usefulInfoList: [],
      checkInOutFormat: 'YYYY-MM-DD(ddd)',
      SpecialRequestItems,
      formState,
      formRules: {
        'contactInfo.email': [{
          required: true,
          type: 'email',
          message: '',
        }],
      },
      validateLastName,
      validateFirstName,
      showSR: false,
      warningText: '',
      warningType: 'warning',
      createVisible: false,
      EOccPricing
    }
  },
  beforeMount() {
    this.selectedMenuKeys = ['hotel_index']
  },
  mounted() {
  },
  unmounted() {
  },
  watch: {
    'formState.nationality': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.preOrder.SearchData.PaxRoom.Nationality = newValue
        this.confirmRate(this.preOrder)
      }
    }
  },
  computed: {
    natiOptions() {
      let isCn = this.isZhCn
      let optionGroups = [{label: this.$t('common_nationality'), options: []}, {
        label: this.$t('other_nationality'),
        options: []
      }]
      AllCountries.forEach(country => {
        let label = country.code + " " + (isCn ? country.name_cn : country.name_en)
        if (CommonNationalityCodes.find(code => code === country.code))
          optionGroups[0].options.push({value: country.code, label: label})
        else
          optionGroups[1].options.push({value: country.code, label: label})
      })

      return optionGroups
    }
  },
  methods: {
    isEmpty,
    getNameCnEn,
    isRefundable,
    getCancelDatetime,
    getNights,
    isERoom,
    getECancelText,isEpsPartiallyRefundable,
    filterOption(input, option) {
      if (option.value === undefined)
        return false;

      return (option.value ? option.value : '').toLowerCase().indexOf(input.toLowerCase()) >= 0 || (option.label ? option.label : '').toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    addGuestName(roomIndex, nameIndex, isAdd) {
      if (isAdd)
        this.formState.guestNames[roomIndex].push({FirstName: '', LastName: ''})
      else
        this.formState.guestNames[roomIndex].splice(nameIndex, 1)
    },
    getCountryName(countryCode){
      let country = AllCountries.filter(item => item.code === countryCode)
      if(country)
        return this.isZhCn ? country[0].name_cn: country[0].name_en
      else
        return countryCode
    },
    backHotel(){
      this.$router.push({
        name: 'hotel_detail', params: {HotelId: this.preOrder.SearchData.HotelId}, query: {
          CheckIn: this.preOrder.SearchData.CheckIn,
          CheckOut: this.preOrder.SearchData.CheckOut,
          PaxRoom: JSON.stringify(this.preOrder.SearchData.PaxRoom),
        }
      })
    },
    confirmRate(preOrder){
      this.initLoading = true
      let params = preOrder.SearchData
      params.CurrenyCode = preOrder.Rate.Currency
      params.TotalPrice = preOrder.Rate.TotalPrice
      params.RatePlanId = preOrder.Rate.RatePlanId
      params.RoomId = preOrder.room_id

      confirmRate(params).then(res => {
        const resData = res.data
        if (resData.Success) {
          this.initLoading = false
          this.roomRate = resData.Data.RoomRate
          this.hotelInfo = resData.Data.HotelInfo
          this.usefulInfoList = resData.Data.UsefulInfoList
          this.Policy = resData.Data.Policy
          //变价提醒
          if(this.roomRate.RatePlans[0].TotalPrice != this.preOrder.Rate.TotalPrice) {
            let diff = this.roomRate.RatePlans[0].TotalPrice - this.preOrder.Rate.TotalPrice
            this.warningText = this.$t('price_change') + (diff > 0 ? " +" : " ") + this.$filters.formatAmount(diff, 0)
            this.preOrder.Rate = this.roomRate.RatePlans[0]
            this.warningType = diff > 0 ? 'warning' : 'success'
          }

          if(isERoom(this.preOrder.Rate.RatePlanId) && this.roomRate.RatePlans[0]?.ExtraData?.EpsRateInfo) {
            this.preOrder.Rate = this.roomRate.RatePlans[0]
            this.preOrder.Rate.EpsRateInfo = JSON.parse(this.roomRate.RatePlans[0].ExtraData.EpsRateInfo)
            this.preOrder.Rate.ExtraData.EpsRateInfo = undefined
            this.EOccPricing = Object.values(this.preOrder.Rate.EpsRateInfo.occupancy_pricing)[0]
          }
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: this.$ta('back|hotel_detail'),
            onOk: this.backHotel
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
    finish1(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.stepIndex = 1
    },
    finish2() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.createVisible = true
      let params = {}
      params = Object.assign(params, this.preOrder.SearchData)
      params.PaxRoom.Nationality = this.formState.nationality
      params.PaxRoom.PaxNames = []
      this.formState.guestNames.forEach(item => {
        item.forEach(name => {
          params.PaxRoom.PaxNames.push(name)
        })
      })
      params.CurrenyCode = this.roomRate.RatePlans[0].Currency
      params.TotalPrice = this.roomRate.RatePlans[0].TotalPrice
      params.RoomId = this.roomRate.room_id
      if(this.roomRate.room_id > 0)
        params.RoomNameEn = this.roomRate.room_name_en
      else
        params.RoomNameEn = this.roomRate.RatePlans[0].RatePlanName
      params.RatePlanId = this.roomRate.RatePlans[0].RatePlanId
      params.ContactName = this.formState.contactInfo.name
      params.ContactPhone = this.formState.contactInfo.tel
      params.ContactEmail = this.formState.contactInfo.email
      params.GroupNo = this.formState.groupBookingId
      params.Specials = this.formState.specialRequests
      if(isERoom(this.preOrder.Rate.RatePlanId) && this.preOrder.Rate.EpsRateInfo) {
        params.EpsRate = JSON.stringify(this.preOrder.Rate.EpsRateInfo)
        if (this.preOrder.Rate.EpsRateInfo.sale_scenario.package)
          params.TicketInfo = this.formState.ticketInfo
      }

      create(params).then(res => {
        const resData = res.data
        if (resData.Success) {
          let orderId = resData.Data
          this.$router.push({name: 'booking_pay', params: {OrderId: orderId}, replace: true})
        } else {
          Modal.error({
            centered: true,
            title: this.$t('error.' + resData.Code),
            okText: this.$ta('back|update'),
            onOk: function (){
              this.stepIndex = 0
            }
          });
        }
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
.order_fill {
  padding: 15px;width: 100%;
}

.order_fill p {
  margin-bottom: 15px;
}

.order_fill .ant-divider-horizontal {
  margin: 10px 0 25px 0;
}

.order_fill .hotel-name {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.order_fill .hotel-name strong {
  font-size: 16px;
  margin-right: 10px
}

.order_fill .hotel-room {
  margin-top: 15px;
}

.order_fill .hotel-room strong {
  margin-right: 30px
}

.order_fill .remark, .order_fill .remark1 {
  font-size:13px;
  font-weight:500;
  color: rgba(0,0,0,0.7)
}
.order_fill .remark {
  margin-left: 10px;
}

.order_fill .p21 {
  padding:0 20px 0 10px
}

.order_fill td { padding: 5px;}

.order_fill .reminder-title {
  margin-top: 10px;
  color: #000000;
  font-weight: 600;
}
.order_fill .reminder-content {
  margin-top: 15px;
  color: rgba(0,0,0,0.7)
}

.order_fill_table {
  width: 100%;
}
.order_fill_table th {
  text-align: left;
  font-weight: 500;
  background-color: #FAFAFA;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.order_fill_table td {
  padding: 10px;
}

.order_price {
  width: 100%;
  color: #FF5C00;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #FF5C00;
  background: rgba(255, 92, 0, 0.2);
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.order_info {
  width: 100%;
  padding: 10px 15px;
  margin: 20px auto;
}

.order_info .ant-divider-horizontal {
  margin: 10px 0;
  font-size: 13px;
  color: #234BA1;
}

.order_info .ant-divider-horizontal svg {
  width: 18px;
  height: 18px;
  margin-bottom: -5px;
}

.order_info table {
  width: 100%;
}

.order_info table td {
  padding: 5px;
}


.hotel-policy-content {
  padding: 5px 20px 10px 0px;
  line-height: 25px;
  width: 100%;
}

.hotel-policy-content1 {
  padding: 5px 20px 10px 0px;
  line-height: 25px;
  width: 100%;
}

.hotel-policy-item {
  margin-right: 20px;
}
.hotel-policy-item-value {
  font-weight: 500;
}

</style>